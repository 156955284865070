import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

export const seizingVehicleSlice = createSlice({
  name: "seizingvehicle",
  initialState: {
    error: "Sorry! No Data Found.",
    message: null,
    loading: false,
    vehicleslist: [],
    model: false,
    drawer: false,
    error_msg: null,
    previewFile:{},
    
    listCount: 0,
    rowdata: {
      id: 0,
    },
    vehiclesParams: {
      no_of_pages: 0,
      page_size: 10,
      page: 1,
      search: "",
      start_date: "",
      end_date: "",
    },
    filterStatus: false,
  },
  reducers: {
    clearVehicleData:(state, action) => {
      return {
        ...state,
        rowdata: {
          id: 0,
        },
        vehicleslist: [],
      }
    },
    getVehicles: (state, action) => {
      if (state.vehiclesParams?.page == 1) {
        return {
          ...state,
          loading: true,
          vehicleslist: [],
        };
      } else {
        return {
          ...state,
          loading: true,
        };
      }
    },
    vehiclesListSuccessful: (state, action) => {
      console.log("action payuload..",action.payload)
      var noofpages = Math.ceil(
        action.payload.count / state.vehiclesParams.page_size
      );
      return {
        ...state,
        listCount: action.payload.count,
        vehicleslist: action.payload.results,
        loading: false,
        vehiclesParams: {
          ...state.vehiclesParams,
          no_of_pages: noofpages,
        },
      };
    },
    SetVehiclesParams: (state, action) => {
      return {
        ...state,
        vehiclesParams: action.payload,
      };
    },

    vehiclesAdd: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    vehiclesAddSuccessful: (state, action) => {
      return {
        ...state,
        message: action.payload,
        loading: false,
        model: false,
        rowdata: {},
      };
    },
    vehiclesDelete: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    addImageUpload: (state, action) => {
      return {
        ...state,
        // imageLooading: true,
        // vehicleImg: [...state.vehicleImg, action.payload],
        // estimationData: {...state.estimationData,   ...action.payload}
        rowdata: {
          ...state.rowdata,
          [action.payload.imageType]: action.payload.file,
        }
      };
    },
    setPreviewImg: (state, action) => {
      return{
        ...state,
        // imageLooading: false,
        rowdata: {
          ...state.rowdata,
          previewFile: action.payload,
        }
      }
    },
    InputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "vehicleType":
          if (action.payload.value == "old") {
            return {
              ...state,
              rowdata: {
                ...state.rowdata,
                vehicleno: null,
                [action.payload.key]: action.payload.value,
              },
            };
          } else {
            return {
              ...state,
              rowdata: {
                ...state.rowdata,
                [action.payload.key]: action.payload.value,
              },
            };
          }
        case "brand_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              brandfamily_id: null,
              model_id: null,
              version_id: null,
            },
          };

        case "brandfamily_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              model_id: null,
              version_id: null,
            },
          };

        case "model_id":
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
              version_id: null,
            },
          };

        default:
          return {
            ...state,
            rowdata: {
              ...state.rowdata,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    isModelVisible: (state, action) => {
      if (action.payload) {
        return {
          ...state,
          model: action.payload,
          rowdata: {},
        };
      } else {
        return {
          ...state,
          model: action.payload,
        };
      }
    },
    
    isDrawerVisible: (state, action) => {
      if (!action.payload) {
        return {
          ...state,
          drawer: action.payload,
          // enquiryData: {},
        };
      } else {
        return {
          ...state,
          drawer: action.payload,
        };
      }
    },
    FilterInputChangeValue: (state, action) => {
      switch (action.payload.key) {
        case "version__brand":
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
              version__brandfamily: null,
              version__model: null,
            },
          };

        case "version__brandfamily":
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
              version__model: null,
            },
          };
        default:
          return {
            ...state,
            vehiclesParams: {
              ...state.vehiclesParams,
              [action.payload.key]: action.payload.value,
            },
          };
      }
    },
    vehicleNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    engineNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    chassisNumberValidate: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    getvehicleByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },
    getvehicleImagesByID: (state) => {
      return {
        ...state,
        loading: true,
      };
    },

    vehicleByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload,
          // vehicleType:
          //   action.payload.vehicletrno == action.payload.vehicleno
          //     ? "new"
          //     : "old",
          vehicle_id: action.payload.vehicle.id,
          year:action.payload.seizure_date,
          seizing_officer_id: action.payload.seizing_officer?.id,
          // seizing_officer:action.payloadseizing_officer.id,
          veh_loan_account_no:action.payload.veh_loan_account_no,
          location_type_id:action.payload.location_type,
          seizing_type_id:action.payload.seize_type,
          owned_by:action.payload?.owned_by,
          // brand_id: action.payload.version?.brand?.id,
          // brandfamily_id: action.payload.version?.brandfamily?.id,
          // model_id: action.payload.version?.model?.id,
          // vehicletrno: action.payload.vehicletrno,
          // year: moment(action.payload?.year, "YYYY"),
          // engineno: action.payload.engineno,
          // emission_id: action.payload.emission?.id,
          // version_id: action.payload.version?.id,
          // chassisno: action.payload.chassisno,
        },
      };
    },
    vehicleImagesByIDSuccessful: (state, action) => {
      return {
        ...state,
        loading: false,
        model: true,
        rowdata: {
          ...action.payload.response2,
          image1:{
            preview:action.payload.response?.vehiclefront_image
          },
          image2:{
            preview:action.payload.response?.vehicleback_image
          },
          image3:{
            preview:action.payload.response?.vehicleleft_image
          },
          image4:{
            preview:action.payload.response?.vehicleright_image
          },
          image5:{
            preview:action.payload.response?.otherimage 
          },
          video1:{
            preview:action.payload.response?.video 
          },
          vehicle_id: action.payload.response2?.vehicle?.id,
          year:action.payload.response2?.seizure_date,
          seizing_officer_id: action.payload.response2?.seizing_officer?.id,
          // seizing_officer:action.payloadseizing_officer.id,
          veh_loan_account_no:action.payload.response2?.veh_loan_account_no,
        },
      };
    },
    // SetVehiclesFilterParams: (state, action) => {
    //   return {
    //     ...state,
    //     vehiclesFilterParams: action.payload,
    //   };
    // },
    fsyncStatusUpdate: (state, action) => {
      return{
        ...state,
        loading: true,
      }
    },
    checkFilterStatus: (state, action) => {
      return {
        ...state,
        filterStatus: action.payload,
      };
    },
    apiError: (state, action) => {
      return {
        ...state,
        loading: false,
        error_msg: action.payload,
      };
    },
  },
});
export const {
  getVehicles,
  vehiclesListSuccessful,
  vehiclesAdd,
  vehiclesAddSuccessful,
  vehiclesDelete,
  InputChangeValue,
  apiError,
  isModelVisible,
  SetVehiclesParams,
  isDrawerVisible,
  FilterInputChangeValue,
  vehicleNumberValidate,
  engineNumberValidate,
  chassisNumberValidate,
  getvehicleByID,
  getvehicleImagesByID,
  vehicleByIDSuccessful,
  vehicleImagesByIDSuccessful,
  fsyncStatusUpdate,
  checkFilterStatus,
  clearVehicleData,
  addImageUpload,
  setPreviewImg
} = seizingVehicleSlice.actions;

export default seizingVehicleSlice.reducer;
